export const smoothScrollToElementWithId = (id) => {
  if (document.getElementById(id)) {
    const navHeight = 120
    window.scrollTo({
      top: document.getElementById(id).offsetTop - navHeight,
      behavior: "smooth",
    })
  } else {
    console.log(`id:${id} not found`)
  }
}
