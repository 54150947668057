import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import toObject from "dayjs/plugin/toObject"
dayjs.extend(customParseFormat)
dayjs.extend(toObject)

/**
 * calculateAPRMonthlyRepayments:
 *
 * amountInPence: number
 * months: number
 * interestRate: number
 *
 * returns { totalRepayment: number, monthlyRepayment: number, financeCharge: number }
 */
const calculateAPRMonthlyRepayments = (amountInPence, months, interestRate) => {
  const periodicRate = interestRate / 100 / 12
  const monthlyRepayment = Math.round(
    amountInPence * (periodicRate / (1 - Math.pow(1 + periodicRate, -months)))
  )
  const totalRepayment = monthlyRepayment * months
  const financeCharge = totalRepayment - amountInPence
  return { totalRepayment, monthlyRepayment, financeCharge }
}

/**
 * calculateOrderPayments
 *
 * boilerAmount: number = price of boiler in pence (important that it's in pence)
 * boilerControlAmount: number = price of boilerControl in pence (important that it's in pence)
 * code: 'up-front' | '50-percent-upfront' | 'finance' = (string) payment type code
 * financeMonths: 12 | 24 | 48 | 60 = (number) amount of finance months
 * interestRate: number = interest rate eg. 9.9
 *
 * returns:
 * an object with data about the payment plan. Should be self explanitory
 */

export const calculateOrderPayments = ({
  boilerAmount,
  boilerControlAmount,
  code,
  financeMonths,
  interestRate,
  labourCost,
  flueCost,
  adminLineItems,
  vat,
}) => {
  const vatPercentage = vat / 100

  const lineItemPricesOnly =
    adminLineItems && adminLineItems.length > 0
      ? adminLineItems.map((item) => item.price)
      : []
  const lineItemPricesTotal =
    adminLineItems && adminLineItems.length > 0
      ? lineItemPricesOnly.reduce(
          (accumulator, currentItem) => accumulator + currentItem
        ) * 100
      : 0 // * 100 because needs to be converted to pence

  const baseCostNoControls =
    (boilerAmount + labourCost + flueCost) * (1 + vatPercentage)

  const baseCost =
    (boilerAmount + labourCost + flueCost) * (1 + vatPercentage) +
    boilerControlAmount +
    lineItemPricesTotal
  /**
   * Handle 'up-front' payment
   * Payment is combined for the boiler and control and the invoices are based on the total amount
   * This payment type has:
   * - a single invoice of the total amount of the boiler and boilerControl
   */
  if (code === "up-front") {
    return {
      code: "up-front",
      upfrontAmount: baseCost,
      baseCostNoControls,
    }
  }

  /**
   * Handle '50-percent-upfront' payment
   * Payments are combined for the boiler and control and the invoices are based on the total amount
   * This payment type has:
   * - an initial invoice of 50% of the boiler amount + the boilerControl amount
   * - a finance term of 12 months
   * - a monthly payment calculated from initial 50% invoice / 12 months
   * - no interest!
   */
  if (code === "50-percent-upfront") {
    // figure out the cancellation date
    const cancellationDate = dayjs().add(financeMonths, "month").add(1, "day")
    const cancellationDateUnix = cancellationDate.unix()
    const cancellationDateISO = cancellationDate.toISOString()

    let upfrontAmount = Math.ceil(baseCost / 2)
    let financeAmount = baseCost - upfrontAmount
    const remaining = financeAmount % financeMonths
    if (remaining) {
      upfrontAmount += remaining
      financeAmount -= remaining
    }
    const monthlyRepayment = financeAmount / financeMonths

    return {
      code: "50-percent-upfront",
      baseCost,
      baseCostNoControls,
      upfrontAmount,
      financeAmount,
      monthlyRepayment,
      financeCharge: 0,
      totalRepayment: baseCost,
      months: financeMonths,
      interestRate,
      cancellationDateUnix,
      cancellationDateISO,
    }
  }

  /**
   * Handle 'finance' payment
   * Payments are combined for the boiler and control and the invoices are based on the total amount
   * This payment type has:
   * - an initial upfront payment of £250
   * - a finance term of variable months M
   * - a variable interest rate X
   * - a monthly payment calculated APR for boiler amount + the boilerControl amount - 25000 deposit over M months with X interest
   */
  if (code === "finance") {
    // figure out the cancellation date
    const cancellationDate = dayjs().add(financeMonths, "month").add(1, "day")
    const cancellationDateUnix = cancellationDate.unix()
    const cancellationDateISO = cancellationDate.toISOString()

    // figure out costs
    const upfrontAmount = 250 * 100 // £250
    const financeAmount = baseCost - upfrontAmount
    let {
      financeCharge,
      monthlyRepayment,
      totalRepayment,
    } = calculateAPRMonthlyRepayments(
      financeAmount,
      financeMonths,
      interestRate
    )

    totalRepayment = totalRepayment + upfrontAmount

    return {
      code: "finance",
      baseCost,
      baseCostNoControls,
      upfrontAmount,
      financeAmount,
      monthlyRepayment,
      financeCharge,
      totalRepayment,
      months: financeMonths,
      interestRate,
      cancellationDateUnix,
      cancellationDateISO,
    }
  }
}
