export function convertToDateWithSuffix(d) {
  return (
    d +
    (31 == d || 21 == d || 1 == d
      ? "st"
      : 22 == d || 2 == d
      ? "nd"
      : 23 == d || 3 == d
      ? "rd"
      : "th")
  )
}
